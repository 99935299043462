import gql from "graphql-tag";

export const updateProfile = gql`
  mutation updateProfile($firstName: String, $lastName: String, $language: String) {
    updateProfile(firstName: $firstName, lastName: $lastName, language: $language) {
      id
      firstName
      lastName
      email
      type
      language
      roles {
        id
        name
      }
      detail {
        ... on Employee {
          id
        }
      }
    }
  }
`;
