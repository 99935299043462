<template>
  <KTCard data-cy="employee-change-password-page" :title="$t('page.changePassword.title')">
    <b-form novalidate>
      <div class="current-password-wrap">
        <InputElement
          v-model="form.passwordCurrent"
          class="password-current-input"
          input-type="password"
          :with-error="errors.passwordCurrent"
          :error-message="$t('validation.required')"
          data-cy="password-current-input"
          :label="$t('label.password.current')"
          @blur="removeErrorClass('passwordCurrent')"
          @input="removeErrorClass('passwordCurrent')"
        />

        <b-link
          :to="resetPasswordRoute"
          data-cy="reset-password-link"
          class="auth-btn forgot-link-min d-block text-primary font-weight-bolder text-hover-primary"
        >
          {{ $t("page.login.forgotPassword") }}
        </b-link>
      </div>

      <InputElement
        v-model="form.passwordNew"
        input-type="password"
        :with-error="errors.passwordNew"
        :error-message="passwordNewErrorMsg"
        data-cy="password-new-input"
        :label="$t('label.password.new')"
        @blur="removeErrorClass('passwordNew')"
        @input="removeErrorClass('passwordNew')"
      />

      <InputElement
        v-model="form.passwordVerify"
        input-type="password"
        :with-error="errors.passwordVerify"
        :error-message="passwordVerifyErrorMsg"
        data-cy="password-verify-input"
        :label="$t('label.password.verify')"
        @blur="removeErrorClass('passwordVerify')"
        @input="removeErrorClass('passwordVerify')"
      />
    </b-form>

    <template v-slot:footer>
      <div class="mr-auto">
        <ButtonElement
          data-cy="save-btn"
          variant="primary"
          text="updatePassword"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement
          data-cy="cancel-btn"
          variant="text-primary"
          text="back"
          @click="onClickBack"
        />
      </div>
    </template>
  </KTCard>
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { changePassword } from "./gql/mutations";
import { mapMutations } from "vuex";

import KTCard from "@/components/card/KTCard";
import ButtonElement from "@/components/ButtonElement";
import InputElement from "@/components/form/InputElement";

export default {
  name: "EmployeeChangePassword",
  components: {
    KTCard,
    ButtonElement,
    InputElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    form: {
      passwordCurrent: "",
      passwordNew: "",
      passwordVerify: "",
    },
    errors: {
      passwordCurrent: false,
      passwordNew: false,
      passwordVerify: false,
    },
    passwordMinLength: 8,
  }),

  computed: {
    resetPasswordRoute() {
      return { name: "EmployeeResetPassword" };
    },

    passwordNewErrorMsg() {
      return !this.form.passwordNew
        ? this.$t("validation.required")
        : this.$t("validation.passwordMinLength", { length: this.passwordMinLength });
    },

    passwordVerifyErrorMsg() {
      return !this.form.passwordVerify
        ? this.$t("validation.required")
        : this.$t("validation.passwordsMustMatch");
    },
  },

  mounted() {
    this.$setBreadcrumbs([], true);
  },

  methods: {
    ...mapMutations(["SET_AUTH"]),

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    isValidForm() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.passwordCurrent = !this.form.passwordCurrent;
      this.errors.passwordNew =
        !this.form.passwordNew || !(this.form.passwordNew.length >= this.passwordMinLength);
      this.errors.passwordVerify =
        !this.form.passwordVerify || this.form.passwordVerify !== this.form.passwordNew;

      return !Object.values(this.errors).some((error) => error);
    },

    async onClickSubmit() {
      if (!this.isValidForm()) return;

      const formData = {
        passwordCurrent: this.form.passwordCurrent,
        passwordNew: this.form.passwordNew,
      };

      const { setPassword } = await this.$post(changePassword, formData);

      if (setPassword) {
        this.SET_AUTH(setPassword);

        this.form.passwordCurrent = "";
        this.form.passwordNew = "";
        this.form.passwordVerify = "";
      }
    },

    onClickBack() {
      this.$router.push({ name: "EmployeeProfileEdit" });
    },
  },
};
</script>

<style lang="scss" scoped>
.current-password-wrap {
  position: relative;

  .password-current-input::v-deep {
    input {
      padding-right: 140px;
    }
  }

  .forgot-link-min {
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 5;
  }
}
</style>
