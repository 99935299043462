<template>
  <KTCard data-cy="employee-profile-edit-page" :title="$t('page.profile.editProfile')">
    <b-form novalidate>
      <InputElement
        v-model="form.firstName"
        data-cy="first-name-input"
        class="mb-10"
        required
        :with-error="errors.firstName"
        :error-message="$t('validation.required')"
        :label="$t('label.firstName')"
        @blur="removeErrorClass('firstName')"
        @input="removeErrorClass('firstName')"
      />

      <InputElement
        v-model="form.lastName"
        data-cy="last-name-input"
        class="mb-10"
        required
        :with-error="errors.lastName"
        :error-message="$t('validation.required')"
        :label="$t('label.lastName')"
        @blur="removeErrorClass('lastName')"
        @input="removeErrorClass('lastName')"
      />

      <InputElement
        v-model="form.email"
        data-cy="email-input"
        disabled
        input-type="email"
        :label="$t('label.email')"
      />
    </b-form>

    <template v-slot:footer>
      <div class="mr-auto">
        <ButtonElement
          data-cy="submit-btn"
          variant="primary"
          text="save"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement variant="text-primary" text="back" @click="onClickBack" />
      </div>
    </template>
  </KTCard>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { updateProfile } from "./gql/mutations";
import KTCard from "@/components/card/KTCard";
import ButtonElement from "@/components/ButtonElement";
import InputElement from "@/components/form/InputElement";

export default {
  name: "EmployeeProfileEdit",
  components: {
    KTCard,
    ButtonElement,
    InputElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    form: {
      firstName: "",
      lastName: "",
      email: "",
    },
    errors: {
      firstName: false,
      lastName: false,
    },
  }),

  computed: {
    ...mapGetters(["userProfile"]),
  },

  watch: {
    userProfile: {
      handler: "onChangeProfile",
      immediate: true,
    },
  },

  mounted() {
    this.$setBreadcrumbs([], true);
  },

  methods: {
    ...mapMutations(["SET_PROFILE"]),

    onChangeProfile(profile) {
      const isExistProfile = profile && Object.keys(profile).length;

      if (isExistProfile) {
        this.form.firstName = profile.firstName;
        this.form.lastName = profile.lastName;
        this.form.email = profile.email;
      }
    },

    checkValueExists(value) {
      return value ? [value] : [];
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    isValidForm() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.lastName = !this.form.lastName;

      return !Object.values(this.errors).some((error) => error);
    },

    async onClickSubmit() {
      if (!this.isValidForm()) return;

      const formData = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
      };

      const response = await this.$post(updateProfile, formData);

      this.SET_PROFILE(response.updateProfile);
    },

    onClickBack() {
      this.$router.push({ name: "EmployeeDashboard" });
    },
  },
};
</script>
