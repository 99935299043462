import gql from "graphql-tag";

export const changePassword = gql`
  mutation setPassword($passwordCurrent: String!, $passwordNew: String!) {
    setPassword(passwordCurrent: $passwordCurrent, passwordNew: $passwordNew) {
      token
      expires
    }
  }
`;
